/** @jsx jsx */
/* eslint no-console:0 no-alert:0 */
import { jsx, useColorMode } from "theme-ui"
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
// TODO import styles from './EmailSubscription.module.css'

const EmailSubscription = () => {
    const [email, setEmail] = useState("");
    const [emailSubmitEnabled, setEmailSubmitEnabled] = useState(false);
    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const _handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        let isValidEmail = false;
        if (
            value && value.length > 6 && value.length <= 256
            && value.includes('@') && !value.endsWith('@') && !value.startsWith('@')
            && value.includes('.') && !value.endsWith('.') && !value.startsWith('.')
        ) {
            isValidEmail = true;
        }

        setEmail(value)
        setEmailSubmitEnabled(isValidEmail)
    }

    const _handleSubmit = e => {
        e.preventDefault()
        setEmailSubmitEnabled(false)

        //console.log('submit', email)

        addToMailchimp(email, [`email`])
            //.then(({ msg, result }) => {
                .then(data => {
                    if (data.result === 'success') {
                        navigate('/about');
                    }
                //console.log('msg', `${result}: ${msg}`)

                //if (result !== 'success') {
                //    throw msg
                //}
                //alert(msg)

                // TODO trigger a toast notification that they were subscribed!

                // Clear the current email value
                setEmail("")
            })
            .catch(err => {
                console.log('err', err)
                alert(err)
            })
    }

    const EmailHeader = () => (
        <h3
            style={{
                textAlign: "center",
                margin: "0.5rem",
                color: isDark ? "#1b202b" : "white"
            }}
        >
            Subscribe for News about our Games :D
        </h3>
    )

    return (
        <div
        style={{
            display: "flex",
            justifyContent: "center",
        }}
        >
            <div
                style={{
                    backgroundColor: "currentColor",
                    opacity: "80%",
                    maxWidth: "640px",
                    minWidth: "50%",
                    alignContent: "center",
                    borderRadius: "1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                }}
            >
                <EmailHeader />
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <form onSubmit={_handleSubmit}>
                        <input
                            type="email"
                            onChange={_handleChange}
                            placeholder="Your email address"
                            name="email"
                            value={ email }
                            style={{
                                marginRight: "0.5rem",
                                width: "14rem",
                                height: "2rem",
                                textAlign: "center",
                            }}
                        />
                        <input
                            type="submit"
                            disabled={!emailSubmitEnabled}
                            value="Subscribe!"
                            style={{
                                width: "7rem",
                                height: "2rem",
                                fontWeight: "bold",
                                opacity: "100%",
                                backgroundColor: isDark ? "black" : "white",
                                color: isDark ? "white" : "#1b202b",
                                display: emailSubmitEnabled ? "inline" : "none",
                            }}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailSubscription
